import {
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Signal,
  computed,
  effect,
  signal,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { CoreNavigationService } from 'src/app/core/service/core-navigation.service';
import { ConfirmDialogType } from 'src/app/shared/component/confirm-dialog/model/confirmation-dialog-types';
import { ButtonType } from '../../../../shared/component/button/button.component';
import { Action } from '../../../../shared/component/card-header/card-header';
import { Step } from '../../../../shared/component/dialog/dialog.component';
import { Option } from '../../../../shared/component/multiselect/multiselect.component';
import { ErrorType, MessageInfo } from '../../../../shared/model/errors';
import { hasInvalidUserInput } from '../../../../shared/model/forms';
import { Subscriptions } from '../../../../shared/util/Subscriptions';
import { Device, DeviceVendor } from '../../model/device.model';
import {
  DeviceRegistrationStateService,
  DeviceRegistration,
  DeviceRegistrationPhase,
} from '../../service/device-registration-state.service';
import { DeviceVendorsService } from '../../service/device-vendors.service';
import {
  ProductInformationService,
  SoftwareOption,
} from 'src/app/product-information/service/product-information.service';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

export interface VirtualLaboratoryPreviewReference {
  id: string;
  name: string;
}

@Component({
  templateUrl: './device-registration-dialog.component.html',
  styleUrls: ['./device-registration-dialog.component.scss'],
})
export class PrepareDeviceRegistrationDialogComponent implements OnInit, OnDestroy {
  @Input() virtualLaboratories: VirtualLaboratoryPreviewReference[] = [];
  @Input() type: ConfirmDialogType = ConfirmDialogType.Info;

  isWorking: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  isLoading = true;
  registration$: Observable<DeviceRegistration | undefined>;

  registeredDevice: Device | undefined = undefined;

  virtualLaboratorySelectOptions: Option[] = [];
  vendorSelectOptions: Option[] = [];

  currentPageIndex: number = 0;

  token?: string;
  softwareVersion = signal('');
  softwareOptions: Signal<SoftwareOption[] | undefined> = signal(undefined);
  softwareDownloadAction: Action[] = [];

  downloadIcon = faFileDownload;

  qatmRegex = /^[qQ]\d{4}\/(0[1-9]|1[0-2])\/$/;
  eltraRegex = /^\d{4,5}\d[01-54]/;

  form = this.fb.group({
    virtualLaboratory: this.fb.control('', {
      nonNullable: true,
      validators: [Validators.required],
      updateOn: 'change',
    }),
    vendor: this.fb.control('', {
      nonNullable: true,
      validators: [Validators.required],
      updateOn: 'change',
    }),
    serialNumber: this.fb.control('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(8)],
      updateOn: 'change',
    }),
  });

  private subscriptions: Subscriptions = new Subscriptions();

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private translocoService: TranslocoService,
    private deviceVendorsService: DeviceVendorsService,
    private changeDetection: ChangeDetectorRef,
    private navigationService: CoreNavigationService,
    private deviceRegistrationStateService: DeviceRegistrationStateService,
    private productFilesService: ProductInformationService,
    private injector: Injector
  ) {
    this.registration$ = deviceRegistrationStateService.registration$;
  }

  ngOnInit(): void {
    this.virtualLaboratorySelectOptions = this.virtualLaboratories.map(virtualLaboratory => {
      return {
        value: virtualLaboratory.id,
        label: virtualLaboratory.name,
      };
    });
    
    console.log(this.virtualLaboratorySelectOptions);

    if (this.virtualLaboratorySelectOptions.length === 1) {
      const control = this.form.controls['virtualLaboratory'];
      control.setValue(this.virtualLaboratorySelectOptions[0].value);
      control.disable();
    }

    this.vendorSelectOptions = this.deviceVendorsService.asOptions();

    this.subscriptions.put(
      this.deviceRegistrationStateService.registration$.subscribe(registration => {
        if (!registration) {
          return;
        }
        if (registration.phase != DeviceRegistrationPhase.WaitingForDevice) {
          this.currentPageIndex = 3;

          this.type = ConfirmDialogType.Success;
          this.registeredDevice = registration.device;
          this.changeDetection.detectChanges();
          return;
        }

        this.token = registration.token?.token;
        this.currentPageIndex = 2;
        this.changeDetection.detectChanges();
      })
    );
    this.subscriptions.put(this.deviceRegistrationStateService.listenForDevice().subscribe());
    this.getRegistrationSoftware();
  }

  get steps(): Step[] {
    return [
      {
        actions: [
          new Action({
            title: this.translocoService.translate('cancel'),
            handler: async (): Promise<void> => {
              return new Promise(resolve => {
                this.activeModal.close(false);
                resolve();
              });
            },
            isEnabled: () => true,
            type: ButtonType.LINK,
          }),
          new Action({
            title: this.translocoService.translate('device.registration-dialog.action.continue'),
            handler: async (): Promise<void> => {
              if (!this.selectRegex(this.form.get('vendor')?.value || '')) {
                this.serialNumber.setErrors({ invalidSerialNumber: true });
                return;
              }
              this.getRegistrationSoftware();
              return new Promise(resolve => {
                this.form.get('vendor')?.value === DeviceVendor.QATM
                  ? this.softwareVersion.set('3.4.x.x')
                  : this.softwareVersion.set('1.7.3.1');
                this.currentPageIndex = 1;
                resolve();
              });
            },
            isEnabled: (): boolean => {
              const isChanging = this.isWorking.getValue();
              return !this.form.invalid && !isChanging;
            },
            type: ButtonType.PRIMARY,
          }),
        ],
      },
      {
        actions: [
          new Action({
            title: this.translocoService.translate('cancel'),
            handler: async (): Promise<void> => {
              return new Promise(resolve => {
                this.activeModal.close(false);
                resolve();
              });
            },
            isEnabled: () => true,
            type: ButtonType.LINK,
          }),
          new Action({
            title: this.translocoService.translate('device.registration-dialog.action.confirm-software'),
            handler: async (): Promise<void> => {
              return this.doRegisterDevice();
            },
            isEnabled: (): boolean => true,
            isWorking: (): boolean => {
              return this.isWorking.getValue();
            },
            type: ButtonType.PRIMARY,
          }),
        ],
      },
      {
        actions: [
          new Action({
            title: this.translocoService.translate('cancel'),
            handler: async (): Promise<void> => {
              return new Promise(resolve => {
                this.deviceRegistrationStateService.cancelRegistration();
                this.activeModal.close(false);
                resolve();
              });
            },
            isEnabled: () => true,
            type: ButtonType.PRIMARY,
          }),
        ],
      },
      {
        actions: [
          new Action({
            title: this.translocoService.translate('close'),
            handler: async (): Promise<void> => {
              return new Promise(resolve => {
                this.activeModal.close(false);
                this.deviceRegistrationStateService.cancelRegistration();
                resolve();
              });
            },
            isEnabled: () => true,
            type: ButtonType.LINK,
          }),
          new Action({
            title: this.translocoService.translate('device.registration-dialog.action.add-another-device'),
            handler: async (): Promise<void> => {
              return new Promise(resolve => {
                this.currentPageIndex = 0;
                resolve();
              });
            },
            isEnabled: () => true,
            type: ButtonType.PRIMARY_OUTLINE,
          }),
          new Action({
            title: this.translocoService.translate('device.registration-dialog.action.open-device-details'),
            handler: async (): Promise<void> => {
              return new Promise(_resolve => {
                this.activeModal.close(false);
                this.deviceRegistrationStateService.cancelRegistration();
                if (this.registeredDevice) {
                  this.navigationService.navigateToDeviceDetails(
                    this.registeredDevice.vendor,
                    this.registeredDevice.type,
                    this.registeredDevice.model.baseModelIdentification?.id ||
                      this.registeredDevice.model.identification.id,
                    this.registeredDevice.model.baseModelIdentification?.version ||
                      this.registeredDevice.model.identification.version ||
                      'latest',
                    this.registeredDevice.id
                  );
                }
              });
            },
            isEnabled: () => true,
            type: ButtonType.MODAL_SUCCESS,
          }),
        ],
      },
    ];
  }

  getRegistrationSoftware() {
    const serialNumber = this.form.get('serialNumber')?.value;
    if (!serialNumber) return;

    const capitalizedSerialNumber = serialNumber.charAt(0).toUpperCase() + serialNumber.slice(1);
    const body = { vendor: this.form.get('vendor')?.value, serialNumber: capitalizedSerialNumber };

    this.subscriptions.put(
      this.productFilesService.getRegistrationSoftware(body).subscribe(() => {
        this.isLoading = false;
      })
    );
    this.softwareOptions = computed(() => {
      return this.productFilesService.softwareOptions();
    });

    effect(
      () => {
        this.softwareDownloadAction =
          this.softwareOptions()?.map(
            option =>
              new Action({
                title: this.translocoService.translate('download'),
                handler: async (): Promise<void> => {
                  return new Promise(resolve => {
                    this.downloadFile(option.id);
                    resolve();
                  });
                },
                isEnabled: () => true,
                type: ButtonType.PRIMARY,
              })
          ) || [];
      },
      { injector: this.injector }
    );
  }

  doRegisterDevice() {
    this.isWorking.next(true);

    this.subscriptions.put(
      this.deviceRegistrationStateService
        .initiateRegistration(
          this.form.controls['virtualLaboratory'].value,
          this.vendor?.value || '',
          this.serialNumber?.value || ''
        )
        .subscribe(() => {
          this.currentPageIndex = 2;
        })
    );
  }

  get virtualLaboratory(): any {
    return this.form.get('virtualLaboratory');
  }

  get isVirtualLaboratoryValid(): boolean {
    return hasInvalidUserInput(this.virtualLaboratory);
  }

  get virtualLaboratoryInfoMessages(): MessageInfo | undefined {
    const errors = this.virtualLaboratory.errors;
    if (!errors) {
      return;
    }
    if (errors?.required) {
      return {
        description: 'error.no-item-selected',
        type: ErrorType.VALIDATION_ERROR,
      };
    }
  }

  get vendor(): any {
    return this.form.get('vendor');
  }

  get isVendorValid(): boolean {
    return hasInvalidUserInput(this.vendor);
  }

  get vendorInfoMessages(): MessageInfo | undefined {
    const errors = this.vendor.errors;
    if (!errors) {
      return;
    }
    if (errors?.required) {
      return {
        description: 'error.no-item-selected',
        type: ErrorType.VALIDATION_ERROR,
      };
    }
  }

  get serialNumber(): any {
    return this.form.get('serialNumber');
  }

  get isSerialNumberValid(): boolean {
    return hasInvalidUserInput(this.serialNumber) && !this.serialNumber.errors?.invalidSerialNumber;
  }

  get serialNumberInfoMessages(): MessageInfo | undefined {
    const errors = this.serialNumber.errors;
    if (!errors) {
      return;
    }
    if (errors?.required || errors?.minlength) {
      return {
        params: {
          minlength: 8,
        },
        description: 'validation.error.min-length',
        type: ErrorType.VALIDATION_ERROR,
      };
    }
    if (errors?.invalidSerialNumber) {
      return {
        description: 'validation.error.invalid-serial-number',
        type: ErrorType.VALIDATION_ERROR,
      };
    }
  }

  selectRegex(vendor: string): boolean {
    return vendor === DeviceVendor.QATM
      ? this.validateSerialNumber(this.serialNumber.value, this.qatmRegex)
      : this.validateSerialNumber(this.serialNumber.value, this.eltraRegex);
  }

  validateSerialNumber(serialNumber: string, regex: RegExp): boolean {
    const serialNumberSplit = serialNumber.slice(0, -2);
    const year = parseInt(serialNumber.slice(-2), 10);
    const currentYear = new Date().getFullYear() % 100;

    return regex.test(serialNumberSplit) && year <= currentYear;
  }

  createActions(fileId: string) {
    return [
      new Action({
        title: this.translocoService.translate('download'),
        handler: async (): Promise<void> => {
          return new Promise(resolve => {
            this.downloadFile(fileId);
            resolve();
          });
        },
        isEnabled: () => true,
        type: ButtonType.PRIMARY,
      }),
    ];
  }

  downloadFile(fileId: string) {
    this.productFilesService
      .getUri(fileId)
      .pipe(take(1))
      .subscribe(uri => window.open(uri, '_blank'));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }
}
